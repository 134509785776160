
import {defineComponent, computed, ref, onMounted} from 'vue';
import {DynmapPlayer} from "@/dynmap";
import {useStore} from "@/store";
import {MutationTypes} from "@/store/mutation-types";
import {getMinecraftHead} from '@/util';

const defaultImage = require('@/assets/images/player_face.png');

export default defineComponent({
	name: 'PlayerListItem',
	props: {
		player: {
			type: Object as () => DynmapPlayer,
			required: true
		}
	},
	setup(props) {
		const store = useStore(),
			otherWorld = computed(() => {
				return store.state.configuration.grayHiddenPlayers
					&& (!store.state.currentWorld || store.state.currentWorld.name !== props.player.location.world);
			}),

			title = computed(() => {
				if(props.player.hidden) {
					return 'This player is currently hidden from the map\nDouble-click to follow player when they become visible';
				} else if(otherWorld.value) {
					return 'This player is in another world.\nClick to center on player\nDouble-click to follow player';
				} else {
					return 'Click to center on player\nDouble-click to follow player';
				}
			}),

			pan = () => {
				if(!props.player.hidden) {
					store.commit(MutationTypes.CLEAR_FOLLOW_TARGET, undefined);
					store.commit(MutationTypes.SET_PAN_TARGET, props.player);
				}
			},

			follow = () => store.commit(MutationTypes.SET_FOLLOW_TARGET, props.player),

			onKeydown = (e: KeyboardEvent) => {
				if(e.key !== ' ' && e.key !== 'Enter') {
					return;
				}

				if(e.shiftKey) {
					follow();
				} else {
					if(!props.player.hidden) {
						pan();
					}
				}
			};

		let image = ref(defaultImage);

		onMounted(() => {
			if(store.state.components.playerMarkers && store.state.components.playerMarkers.showSkinFaces) {
				getMinecraftHead(props.player, '16').then((result) => image.value = result.src).catch(() => {});
			}
		});

		return {
			image,
			title,
			otherWorld,
			pan,
			follow,
			onKeydown
		}
	},

});
