
import WorldListItem from './WorldListItem.vue';
import {defineComponent} from 'vue';
import {useStore} from "@/store";

export default defineComponent({
	name: 'WorldList',
	components: {
		WorldListItem
	},

	computed: {
		heading() {
			return useStore().state.messages.mapTypes;
		},

		worlds() {
			return useStore().state.worlds;
		}
	},
});

