
	import {defineComponent, ref, computed, watch} from "@vue/runtime-core";
	import {useStore} from "@/store";
	import ChatMessage from "@/components/chat/ChatMessage.vue";
	import {ActionTypes} from "@/store/action-types";
	import ChatError from "@/errors/ChatError";

	export default defineComponent({
		components: {
			ChatMessage
		},
		setup() {
			const store = useStore(),
				componentSettings = computed(() => store.state.components.chatBox),
				chatBoxVisible = computed(() => store.state.ui.visibleElements.has('chat')),

				loginRequired = computed(() => {
					return store.state.components.chatSending && store.state.components.chatSending.loginRequired
						&& !store.state.loggedIn;
				}),
				sendingEnabled = computed(() => store.state.components.chatSending && !loginRequired.value),
				messageMaxLength = computed(() => store.state.components.chatSending?.maxLength),

				chatInput = ref<HTMLInputElement | null>(null),
				enteredMessage = ref<string>(""),
				sendingMessage = ref<boolean>(false),
				sendingError = ref<string | null>(null),

				messages = computed(() => {
					if(componentSettings.value!.messageHistory) {
						return store.state.chat.messages.slice(0, componentSettings.value!.messageHistory);
					} else {
						return store.state.chat.messages;
					}
				}),

				sendMessage = () => {
					const message = enteredMessage.value.trim().substring(0, messageMaxLength.value);

					if(!message) {
						return;
					}

					sendingMessage.value = true;
					sendingError.value = null;

					store.dispatch(ActionTypes.SEND_CHAT_MESSAGE, message).then(() => {
						enteredMessage.value = "";
						sendingError.value = null;
					}).catch(e => {
						if(e instanceof ChatError) {
							sendingError.value = e.message;
						} else {
							sendingError.value = `An unexpected error occurred. See console for details.`;
						}
					}).finally(() => {
						sendingMessage.value = false;

						requestAnimationFrame(() => chatInput.value!.focus());
					});
				};

			watch(chatBoxVisible, newValue => {
				if(newValue && sendingEnabled.value) {
					requestAnimationFrame(() => chatInput.value!.focus());
				}
			});

			return {
				chatInput,
				enteredMessage,
				sendMessage,
				messages,
				loginRequired,
				sendingEnabled,
				sendingMessage,
				sendingError,
				messageMaxLength
			}
		}
	})
