
	import {defineComponent, ref, onMounted, computed} from "@vue/runtime-core";
	import {DynmapChat} from "@/dynmap";
	import {getMinecraftHead} from '@/util';
	import {useStore} from "@/store";

	const defaultImage = require('@/assets/images/player_face.png');

	export default defineComponent({
		props: {
			message: {
				type: Object as () => DynmapChat,
				required: true,
			}
		},
		setup(props) {
			const store = useStore();
			let image = ref(defaultImage),
				showFace = computed(() => store.state.components.chatBox?.showPlayerFaces && props.message.playerAccount),
				showSender = computed(() => props.message.playerName && props.message.type === 'chat'),
				messageContent = computed(() => {
					switch(props.message.type) {
						case 'chat':
							return props.message.message;
						case 'playerjoin':
							if(props.message.playerName) {
								return store.state.messages.playerJoin
									.replace('%playername%', props.message.playerName);
							} else {
								return store.state.messages.anonymousJoin;
							}
						case 'playerleave':
							if(props.message.playerName) {
								return store.state.messages.playerQuit
									.replace('%playername%', props.message.playerName);
							} else {
								return store.state.messages.anonymousQuit;
							}
					}
				})

			onMounted(() => {
				if(showFace.value) {
					getMinecraftHead(props.message.playerAccount as string, '16')
						.then((result) => image.value = result.src).catch(() => {});
				}
			});

			return {
				image,
				showFace,
				showSender,
				messageContent
			}
		}
	})
