/*
 * Copyright 2020 James Lyne
 *
 * Some portions of this file were taken from https://github.com/webbukkit/dynmap.
 * These portions are Copyright 2020 Dynmap Contributors.
 *
 *    Licensed under the Apache License, Version 2.0 (the "License");
 *    you may not use this file except in compliance with the License.
 *    You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 *    Unless required by applicable law or agreed to in writing, software
 *    distributed under the License is distributed on an "AS IS" BASIS,
 *    WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 *    See the License for the specific language governing permissions and
 *    limitations under the License.
 */

import {Util, LatLng, Class} from 'leaflet';
import {Coordinate} from "@/dynmap";

export interface DynmapProjectionOptions {}

export interface DynmapProjection {
	locationToLatLng(location: Coordinate): LatLng;
	latLngToLocation(latLng: LatLng, y: number): Coordinate;
}

export class DynmapProjection extends Class {

	constructor(options?: DynmapProjectionOptions) {
		super();
		Util.setOptions(this, options);
	}

	locationToLatLng(location: Coordinate): LatLng {
		return new LatLng(location.x, location.z);
	}

	latLngToLocation(latLng: LatLng, y: number): Coordinate {
		return {x: latLng.lat, y, z: latLng.lng};
	}
}
