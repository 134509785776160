/*
 * Copyright 2020 James Lyne
 *
 *    Licensed under the Apache License, Version 2.0 (the "License");
 *    you may not use this file except in compliance with the License.
 *    You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 *    Unless required by applicable law or agreed to in writing, software
 *    distributed under the License is distributed on an "AS IS" BASIS,
 *    WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 *    See the License for the specific language governing permissions and
 *    limitations under the License.
 */

export enum ActionTypes {
	LOAD_CONFIGURATION = "loadConfiguration",
	GET_UPDATE = "getUpdate",
	GET_MARKER_SETS = "getMarkerSets",
	SET_PLAYERS = "setPlayers",
	POP_MARKER_UPDATES = "popMarkerUpdates",
	POP_AREA_UPDATES = "popAreaUpdates",
	POP_CIRCLE_UPDATES = "popCircleUpdates",
	POP_LINE_UPDATES = "popLineUpdates",
	POP_TILE_UPDATES = "popTileUpdates",
	SEND_CHAT_MESSAGE = "sendChatMessage",
}