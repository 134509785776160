
import PlayerMarker from "@/components/map/marker/PlayerMarker.vue";
import {defineComponent, computed, watch} from "@vue/runtime-core";
import {useStore} from "@/store";
import {LayerGroup} from 'leaflet';
import DynmapMap from "@/leaflet/DynmapMap";

export default defineComponent({
	components: {
		PlayerMarker
	},

	props: {
		leaflet: {
			type: Object as () => DynmapMap,
			required: true,
		}
	},

	setup(props) {
		const store = useStore(),
			playerPane = props.leaflet.createPane('players'),
			players = computed(() => store.state.players),
			playerCount = computed(() => store.state.players.size),
			componentSettings = store.state.components.playerMarkers,
			layerGroup = new LayerGroup([],{
				pane: 'players'
			});

		watch(playerCount, (newValue) => playerPane.classList.toggle('no-animations', newValue > 150))

		return {
			players,
			componentSettings,
			layerGroup,
		}
	},

	mounted() {
		if(!this.componentSettings!.hideByDefault) {
			this.leaflet.getLayerManager().addLayer(
				this.layerGroup,
				true,
				useStore().state.messages.players,
				this.componentSettings!.layerPriority);
		}
	},

	unmounted() {
		this.leaflet.removeLayer(this.layerGroup);
	},

	render() {
		return null;
	}
})
