
import {defineComponent, computed} from "@vue/runtime-core";
import PlayerList from './sidebar/PlayerList.vue';
import WorldList from './sidebar/WorldList.vue';
import FollowTarget from './sidebar/FollowTarget.vue';
import {useStore} from "@/store";
import SvgIcon from "@/components/SvgIcon.vue";
import {MutationTypes} from "@/store/mutation-types";
import {DynmapUIElement} from "@/dynmap";

export default defineComponent({
	components: {
		SvgIcon,
		PlayerList,
		FollowTarget,
		WorldList,
	},

	setup() {
		const store = useStore(),
			currentlyVisible = computed(() => store.state.ui.visibleElements),
			previouslyVisible = computed(() => store.state.ui.previouslyVisibleElements),
			smallScreen = computed(() => store.state.ui.smallScreen),
			mapCount = computed(() => store.state.maps.size),
			following = computed(() => store.state.followTarget),

			toggleElement = (element: DynmapUIElement) => {
				store.commit(MutationTypes.TOGGLE_UI_ELEMENT_VISIBILITY, element);
			},

			followActive = computed(() => {
				//Show following alongside playerlist on small screens
				return (!smallScreen.value && following)
					|| (smallScreen.value && currentlyVisible.value.has('players'));
			});

		return {
			mapCount,
			currentlyVisible,
			previouslyVisible,
			toggleElement,
			followActive,
			following,
		}
	}
})

