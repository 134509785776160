
import {DynmapPlayer} from "@/dynmap";
import {useStore} from "@/store";
import {MutationTypes} from "@/store/mutation-types";
import {defineComponent, onMounted, ref, watch} from "@vue/runtime-core";
import {getMinecraftHead} from '@/util';

const defaultImage = require('@/assets/images/player_face.png');

export default defineComponent({
	name: 'FollowTarget',
	props: {
		target: {
			type: Object as () => DynmapPlayer,
			required: true
		}
	},
	setup(props) {
		const store = useStore(),
			image = ref(defaultImage),
			account = ref(props.target.account),

			unfollow = () => {
				useStore().commit(MutationTypes.CLEAR_FOLLOW_TARGET, undefined);
			},
			onKeydown = (e: KeyboardEvent) => {
				if(e.key !== ' ') {
					return;
				}

				unfollow();
			},
			updatePlayerImage = () => {
				image.value = defaultImage;

				if(store.state.components.playerMarkers && store.state.components.playerMarkers.showSkinFaces) {
					getMinecraftHead(props.target, '16').then((result) => image.value = result.src).catch(() => {});
				}
			};

		watch(account, () => updatePlayerImage());
		onMounted(() => updatePlayerImage());

		return {
			image,
			onKeydown,
			unfollow
		}
	},
});
